import React from 'react';

export default function Vimeo() {
  return (
    <section id="vimeo" className="vimeo-section">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 mx-auto text-center">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe src="https://player.vimeo.com/video/468430612"  frameBorder="0" allow="autoplay; fullscreen" allowFullScreen style={{
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
}}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


