import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Vimeo from '../components/Vimeo';
import Header from '../components/Header';

import projectHeader from '../assets/images/content-01.jpg';
import projectImage1 from '../assets/images/content-02.jpg';
import projectImage2 from '../assets/images/content-03.jpg';
import projectImage3 from '../assets/images/content-04.jpg';
import projectImage4 from '../assets/images/content-05.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead mb-4"   id="home">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase"><div>{config.heading}</div><div>{config.heading2}</div></h1>
          <h2 className="text-white mx-auto mt-2">
            <span className="subheading">{config.subHeading} </span>
            <span className="subheading2">{config.subHeading2}</span>
          </h2>
          <Scroll type="id" element="projects">
            <a href="#about" className="scroll-down">
              <svg width="3em" height="3em" viewBox="0 0 16 16" className="bi bi-arrow-down-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
              </svg>
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <Vimeo />



    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={projectHeader} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <div className="title">
                <h4>Sales Area Central Europe: what‘s behind the big picture?</h4>
              </div>
              <p className="text-black-50 mb-0">
              Change processes always feel slightly unfamiliar at first. 
              The big advantage: as soon as the new structures are put into practice, new horizons and potentials open up. 
              Discover the advantages of the new Sales Area CE in detail and become part of the change at B/S/H/.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={projectImage1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">Consumer centricity: our marketing strategy</h4>
                  <p className="mb-0 text-white-50">
                  All the necessary input to fully understand the power of our new marketing strategy and 
                  why we put the consumer at the heart of our product development.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters" id="footer">
          <div className="col-lg-6">
            <img className="img-fluid" src={projectImage2} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">Sell #LikeABosch: the B/S/H/ marketing powerhouse</h4>
                  <p className="mb-0 text-white-50">
                  Here you find all the details for our upcoming innovative campaigns in 2021, 
                  broken down to each CE country.
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={projectImage3} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">Crossfunctional teams at B/S/H/ sales: how does it work?</h4>
                  <p className="mb-0 text-white-50">
                  BSH has its finger on the pulse of time. 
                  Thanks to the use of state-of-the-art technology and software, as well as our new sales and marketing structure, 
                  international collaboration and projects can be realized efficiently at any time. 
                  How? Here it is explained to you in detail.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters" id="footer">
          <div className="col-lg-6">
            <img className="img-fluid" src={projectImage4} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">Clear targets: what do we want to achieve in 2021?</h4>
                  <p className="mb-0 text-white-50">
                  Become the largest marketing organization in the industry with a high level of networking and synergies. 
                  How do we intend to achieve this ambitious goal? With you. Find out more here.
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
